<template>
  <div class="q-pa-md">
    <div class="q-gutter-y-md" style="height: 100%">
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
          no-caps
        >
          <q-tab
            name="lateSubmission"
            :label="getRes('CustMenu.Title.LateSubmission')"
          />
          <q-tab
            name="bidderListApproval"
            :label="getRes('CustMenu.Title.BidderListApproval')"
          />
          <q-tab
            name="extensionAndAbortion"
            :label="getRes('CustMenu.Title.ExtensionAndAbortion')"
          />
          <q-tab
            name="TenderMaster"
            :label="getRes('CustMenu.Title.TenderMaster')"
          />
          <q-tab
            name="RFQMaster"
            :label="getRes('CustMenu.Title.RFQMaster')"
          />
          <q-tab
            name="LightWeightSupplierMaster"
            :label="getRes('CustMenu.Title.LightWeightSupplier')"
          />
          <q-tab
            name="RegisteredSupplierMaster"
            :label="getRes('CustMenu.Title.RegisteredSupplier')"
          />
        </q-tabs>

        <q-separator/>

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="lateSubmission">
            <div class="row q-pb-sm">
              <div class="col-6">
                <div class="row items-center">
                  <div class="col-2 cust-label-container">
                    <label>{{ getRes("CustMenu.IssueDate") }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateFrom"
                      :rules="['date']"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateFrom"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{
                        getRes("Form.Field.to")
                      }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateTo"
                      :rules="['date']"
                      :error="!!issueDateToError"
                      :error-message="issueDateToError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateTo"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="row q-pb-sm">-->
            <!--              <div class="col-6">-->
            <!--                <div class="row items-center">-->
            <!--                  <div class="col-2 cust-label-container">-->
            <!--                    <label>Closing Date</label>-->
            <!--                  </div>-->
            <!--                  <div class="col">-->
            <!--                    <q-input-->
            <!--                      outlined-->
            <!--                      dense-->
            <!--                      hide-bottom-space-->
            <!--                      v-model="closingDateFrom"-->
            <!--                      :rules="['date']"-->
            <!--                    >-->
            <!--                      <template v-slot:prepend>-->
            <!--                        <q-icon name="event" class="cursor-pointer">-->
            <!--                          <q-popup-proxy-->
            <!--                            transition-show="scale"-->
            <!--                            transition-hide="scale"-->
            <!--                          >-->
            <!--                            <q-date-->
            <!--                              @input="closingDateChange"-->
            <!--                              v-model="closingDateFrom"-->
            <!--                            >-->
            <!--                              <div class="row items-center justify-end">-->
            <!--                                <q-btn-->
            <!--                                  v-close-popup-->
            <!--                                  label="Close"-->
            <!--                                  color="primary"-->
            <!--                                  flat-->
            <!--                                />-->
            <!--                              </div>-->
            <!--                            </q-date>-->
            <!--                          </q-popup-proxy>-->
            <!--                        </q-icon>-->
            <!--                      </template>-->
            <!--                    </q-input>-->
            <!--                  </div>-->

            <!--                  <div class="col-auto cust-label-container">-->
            <!--                    <label style="padding: 0 10px">{{getRes('To')}}</label>-->
            <!--                  </div>-->
            <!--                  <div class="col">-->
            <!--                    <q-input-->
            <!--                      outlined-->
            <!--                      dense-->
            <!--                      hide-bottom-space-->
            <!--                      v-model="closingDateTo"-->
            <!--                      :rules="['date']"-->
            <!--                      :error="!!closingDateToError"-->
            <!--                      :error-message="closingDateToError"-->
            <!--                    >-->
            <!--                      <template v-slot:prepend>-->
            <!--                        <q-icon name="event" class="cursor-pointer">-->
            <!--                          <q-popup-proxy-->
            <!--                            transition-show="scale"-->
            <!--                            transition-hide="scale"-->
            <!--                          >-->
            <!--                            <q-date-->
            <!--                              @input="closeingDateChange"-->
            <!--                              v-model="closingDateTo"-->
            <!--                            >-->
            <!--                              <div class="row items-center justify-end">-->
            <!--                                <q-btn-->
            <!--                                  v-close-popup-->
            <!--                                  label="Close"-->
            <!--                                  color="primary"-->
            <!--                                  flat-->
            <!--                                />-->
            <!--                              </div>-->
            <!--                            </q-date>-->
            <!--                          </q-popup-proxy>-->
            <!--                        </q-icon>-->
            <!--                      </template>-->
            <!--                    </q-input>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="row items-center">-->
            <!--              <div class="col-1 cust-label-container">-->
            <!--                <label>Tender Ref.</label>-->
            <!--              </div>-->
            <!--              <div class="col-auto cust-label-container">-->
            <!--                <q-input-->
            <!--                  dense-->
            <!--                  outlined-->
            <!--                  v-model="tenderRef"-->
            <!--                  style="width: 500px"-->
            <!--                  onchange="this.handleChange()"-->
            <!--                  name="ref"-->
            <!--                >-->
            <!--                </q-input>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="row items-center">-->
            <!--              <div class="col-1 cust-label-container">-->
            <!--                <label>Category</label>-->
            <!--              </div>-->
            <!--              <div class="col-auto cust-label-container">-->
            <!--                <q-input-->
            <!--                  dense-->
            <!--                  outlined-->
            <!--                  v-model="category"-->
            <!--                  style="width: 500px"-->
            <!--                  onchange="this.handleChange()"-->
            <!--                >-->
            <!--                </q-input>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="row items-center">-->
            <!--              <div class="col-1 cust-label-container">-->
            <!--                <label>Buyer</label>-->
            <!--              </div>-->
            <!--              <div class="col-auto cust-label-container">-->
            <!--                <q-input-->
            <!--                  dense-->
            <!--                  outlined-->
            <!--                  v-model="buyer"-->
            <!--                  style="width: 500px"-->
            <!--                  onchange="this.handleChange()"-->
            <!--                >-->
            <!--                </q-input>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="row items-center">-->
            <!--              <div class="col-1 cust-label-container">-->
            <!--                <label>CapexConsultancy</label>-->
            <!--              </div>-->
            <!--              <div class="col-auto cust-label-container">-->
            <!--                <q-select-->
            <!--                  v-model="capexConsultancy"-->
            <!--                  :options="capexConsultancyList"-->
            <!--                  onchange="this.handleChange()"-->
            <!--                  outlined-->
            <!--                  dense-->
            <!--                  style="width: 250px"-->
            <!--                >-->
            <!--                </q-select>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="row items-center">-->
            <!--              <div class="col-1 cust-label-container">-->
            <!--                <label>capexRoutine</label>-->
            <!--              </div>-->
            <!--              <div class="col-auto cust-label-container">-->
            <!--                <q-select-->
            <!--                  v-model="capexRoutine"-->
            <!--                  :options="capexRoutineList"-->
            <!--                  onchange="this.handleChange()"-->
            <!--                  outlined-->
            <!--                  dense-->
            <!--                  style="width: 250px"-->
            <!--                >-->
            <!--                </q-select>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="row items-center">
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Export')"
                  @click="exportExcel('lateSubmission')"
                ></q-btn>
              </div>
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Clear')"
                  @click="filter"
                ></q-btn>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="bidderListApproval">
            <div class="row q-pb-sm">
              <div class="col-6">
                <div class="row items-center">
                  <div class="col-2 cust-label-container">
                    <label>{{ getRes("CustMenu.IssueDate") }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateFrom"
                      :rules="['date']"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateFrom"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{
                        getRes("Form.Field.to")
                      }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateTo"
                      :rules="['date']"
                      :error="!!issueDateToError"
                      :error-message="issueDateToError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateTo"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row items-center">
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Export')"
                  @click="exportExcel('bidderList')"
                ></q-btn>
              </div>
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Clear')"
                  @click="filter"
                ></q-btn>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="extensionAndAbortion">
            <div class="row q-pb-sm">
              <div class="col-6">
                <div class="row items-center">
                  <div class="col-2 cust-label-container">
                    <label>{{ getRes("CustMenu.IssueDate") }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateFrom"
                      :rules="['date']"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateFrom"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{
                        getRes("Form.Field.to")
                      }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateTo"
                      :rules="['date']"
                      :error="!!issueDateToError"
                      :error-message="issueDateToError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateTo"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row items-center">
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Export')"
                  @click="exportExcel('extensionAndAbortion')"
                ></q-btn>
              </div>
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Clear')"
                  @click="filter"
                ></q-btn>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="TenderMaster">
            <div class="row q-pb-sm">
              <div class="col-6">
                <div class="row items-center">
                  <div class="col-2 cust-label-container">
                    <label>{{ getRes("CustMenu.IssueDate") }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateFrom"
                      :rules="['date']"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateFrom"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{
                        getRes("Form.Field.to")
                      }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateTo"
                      :rules="['date']"
                      :error="!!issueDateToError"
                      :error-message="issueDateToError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateTo"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row items-center">
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Export')"
                  @click="exportExcel('tenderMaster')"
                ></q-btn>
              </div>
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Clear')"
                  @click="filter"
                ></q-btn>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="RFQMaster">
            <div class="row q-pb-sm">
              <div class="col-6">
                <div class="row items-center">
                  <div class="col-2 cust-label-container">
                    <label>{{ getRes("CustMenu.IssueDate") }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateFrom"
                      :rules="['date']"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateFrom"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{
                        getRes("Form.Field.to")
                      }}</label>
                  </div>
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="issueDateTo"
                      :rules="['date']"
                      :error="!!issueDateToError"
                      :error-message="issueDateToError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="issueDateTo"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row items-center">
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Export')"
                  @click="exportExcel('rfqMaster')"
                ></q-btn>
              </div>
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  no-caps
                  outline
                  color="primary"
                  :label="getRes('System.Button.Clear')"
                  @click="filter"
                ></q-btn>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="LightWeightSupplierMaster">
            <div class="row q-pb-sm">
              <div class="col">
                <div class="row items-center">

<!--                  <div class="col-1 cust-label-container">-->
<!--                    <label>{{ getRes("CustMenu.IssueDate") }}</label>-->
<!--                  </div>-->
<!--                  <div class="col-2">-->
<!--                    <q-input-->
<!--                      outlined-->
<!--                      dense-->
<!--                      hide-bottom-space-->
<!--                      v-model="issueDateFrom"-->
<!--                      :rules="['date']"-->
<!--                    >-->
<!--                      <template v-slot:prepend>-->
<!--                        <q-icon name="event" class="cursor-pointer">-->
<!--                          <q-popup-proxy-->
<!--                            transition-show="scale"-->
<!--                            transition-hide="scale"-->
<!--                          >-->
<!--                            <q-date-->
<!--                              @input="issueDateChange"-->
<!--                              v-model="issueDateFrom"-->
<!--                            >-->
<!--                              <div class="row items-center justify-end">-->
<!--                                <q-btn-->
<!--                                  v-close-popup-->
<!--                                  :label="getRes('System.Button.Close')"-->
<!--                                  color="primary"-->
<!--                                  flat-->
<!--                                />-->
<!--                              </div>-->
<!--                            </q-date>-->
<!--                          </q-popup-proxy>-->
<!--                        </q-icon>-->
<!--                      </template>-->
<!--                    </q-input>-->
<!--                  </div>-->

<!--                  <div class="col-auto cust-label-container">-->
<!--                    <label style="padding: 0 10px">{{-->
<!--                        getRes("Form.Field.to")-->
<!--                      }}</label>-->
<!--                  </div>-->
<!--                  <div class="col-2">-->
<!--                    <q-input-->
<!--                      outlined-->
<!--                      dense-->
<!--                      hide-bottom-space-->
<!--                      v-model="issueDateTo"-->
<!--                      :rules="['date']"-->
<!--                      :error="!!issueDateToError"-->
<!--                      :error-message="issueDateToError"-->
<!--                    >-->
<!--                      <template v-slot:prepend>-->
<!--                        <q-icon name="event" class="cursor-pointer">-->
<!--                          <q-popup-proxy-->
<!--                            transition-show="scale"-->
<!--                            transition-hide="scale"-->
<!--                          >-->
<!--                            <q-date-->
<!--                              @input="issueDateChange"-->
<!--                              v-model="issueDateTo"-->
<!--                            >-->
<!--                              <div class="row items-center justify-end">-->
<!--                                <q-btn-->
<!--                                  v-close-popup-->
<!--                                  :label="getRes('System.Button.Close')"-->
<!--                                  color="primary"-->
<!--                                  flat-->
<!--                                />-->
<!--                              </div>-->
<!--                            </q-date>-->
<!--                          </q-popup-proxy>-->
<!--                        </q-icon>-->
<!--                      </template>-->
<!--                    </q-input>-->
<!--                  </div>-->

                  <div class="col-2 cust-label-container">
                    <label>{{ getRes("CustMenu.RegistrationDateFrom") }}</label>
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="registrationDateFrom"
                      :rules="['date']"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="registrationDateFrom"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{
                        getRes("Form.Field.to")
                      }}</label>
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="registrationDateTo"
                      :rules="['date']"
                      :error="!!issueDateToError"
                      :error-message="issueDateToError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="registrationDateTo"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
                <div class="row items-center">
                  <div class="col-1 cust-label-container">
                    <label style="padding: 0 10px">{{ getRes("Form.Field.SupplierName") }}</label>
                  </div>
                  <div class="col-5">
                    <q-input
                      v-model="supplierName"
                      label=""
                      :rules="['string']">
                    </q-input>
                  </div>

                  <div class="col-1 cust-label-container">
                    <label style="padding: 0 10px">{{ getRes("Form.Field.SupplierNumber") }}</label>
                  </div>
                  <div class="col-5">
                    <q-input
                      v-model="supplierNumber"
                      label=""
                      :rules="['number']">
                    </q-input>
                  </div>


<!--                  <div class="col-1 cust-label-container">-->
<!--                    <label style="padding: 0 10px">{{ getRes("Form.Field.AdmissionRegion") }}</label>-->
<!--                  </div>-->
<!--                  <div class="col-3">-->
<!--                    <q-input-->
<!--                      v-model="admissionRegion"-->
<!--                      label=""-->
<!--                      :rules="['string']">-->
<!--                    </q-input>-->
<!--                  </div>-->
                </div>
<!--                <div class="row items-center">-->
<!--               -->

<!--                  <div class="col cust-label-container">-->
<!--                    <label style="padding: 0 10px">{{-->
<!--                        getRes("Form.Field.ProductCategoryL1")-->
<!--                      }}</label>-->
<!--                  </div>-->
<!--                  <div class="col">-->
<!--                    <q-input-->
<!--                      v-model="productCategoryL1"-->
<!--                      label=""-->
<!--                      :rules="['string']">-->
<!--                    </q-input>-->
<!--                  </div>-->

<!--                  <div class="col cust-label-container">-->
<!--                    <label style="padding: 0 10px">{{-->
<!--                        getRes("Form.Field.ProductCategoryL2")-->
<!--                      }}</label>-->
<!--                  </div>-->
<!--                  <div class="col">-->
<!--                    <q-input-->
<!--                      v-model="productCategoryL2"-->
<!--                      label=""-->
<!--                      :rules="['string']">-->
<!--                    </q-input>-->
<!--                  </div>-->

<!--                  <div class="col cust-label-container">-->
<!--                    <label style="padding: 0 10px">{{-->
<!--                        getRes("Form.Field.ProductCategoryL3")-->
<!--                      }}</label>-->
<!--                  </div>-->
<!--                  <div class="col">-->
<!--                    <q-input-->
<!--                      v-model="productCategoryL3"-->
<!--                      label=""-->
<!--                      :rules="['string']">-->
<!--                    </q-input>-->
<!--                  </div>-->

<!--                  <div class="col cust-label-container">-->
<!--                    <label style="padding: 0 10px">{{-->
<!--                        getRes("Form.Field.ProductCategoryL4")-->
<!--                      }}</label>-->
<!--                  </div>-->
<!--                  <div class="col">-->
<!--                    <q-input-->
<!--                      v-model="productCategoryL4"-->
<!--                      label=""-->
<!--                      :rules="['string']">-->
<!--                    </q-input>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="row items-center">
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  outline
                  color="primary"
                  no-caps
                  :label="getRes('System.Button.Export')"
                  @click="exportExcel('lightWeightSupplierMaster')"
                ></q-btn>
              </div>
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  outline
                  color="primary"
                  no-caps
                  :label="getRes('System.Button.Clear')"
                  @click="filter"
                ></q-btn>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="RegisteredSupplierMaster">
            <div class="row q-pb-sm">
              <div class="col">
                <div class="row items-center">

                  <!--                  <div class="col-1 cust-label-container">-->
                  <!--                    <label>{{ getRes("CustMenu.IssueDate") }}</label>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-2">-->
                  <!--                    <q-input-->
                  <!--                      outlined-->
                  <!--                      dense-->
                  <!--                      hide-bottom-space-->
                  <!--                      v-model="issueDateFrom"-->
                  <!--                      :rules="['date']"-->
                  <!--                    >-->
                  <!--                      <template v-slot:prepend>-->
                  <!--                        <q-icon name="event" class="cursor-pointer">-->
                  <!--                          <q-popup-proxy-->
                  <!--                            transition-show="scale"-->
                  <!--                            transition-hide="scale"-->
                  <!--                          >-->
                  <!--                            <q-date-->
                  <!--                              @input="issueDateChange"-->
                  <!--                              v-model="issueDateFrom"-->
                  <!--                            >-->
                  <!--                              <div class="row items-center justify-end">-->
                  <!--                                <q-btn-->
                  <!--                                  v-close-popup-->
                  <!--                                  :label="getRes('System.Button.Close')"-->
                  <!--                                  color="primary"-->
                  <!--                                  flat-->
                  <!--                                />-->
                  <!--                              </div>-->
                  <!--                            </q-date>-->
                  <!--                          </q-popup-proxy>-->
                  <!--                        </q-icon>-->
                  <!--                      </template>-->
                  <!--                    </q-input>-->
                  <!--                  </div>-->

                  <!--                  <div class="col-auto cust-label-container">-->
                  <!--                    <label style="padding: 0 10px">{{-->
                  <!--                        getRes("Form.Field.to")-->
                  <!--                      }}</label>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-2">-->
                  <!--                    <q-input-->
                  <!--                      outlined-->
                  <!--                      dense-->
                  <!--                      hide-bottom-space-->
                  <!--                      v-model="issueDateTo"-->
                  <!--                      :rules="['date']"-->
                  <!--                      :error="!!issueDateToError"-->
                  <!--                      :error-message="issueDateToError"-->
                  <!--                    >-->
                  <!--                      <template v-slot:prepend>-->
                  <!--                        <q-icon name="event" class="cursor-pointer">-->
                  <!--                          <q-popup-proxy-->
                  <!--                            transition-show="scale"-->
                  <!--                            transition-hide="scale"-->
                  <!--                          >-->
                  <!--                            <q-date-->
                  <!--                              @input="issueDateChange"-->
                  <!--                              v-model="issueDateTo"-->
                  <!--                            >-->
                  <!--                              <div class="row items-center justify-end">-->
                  <!--                                <q-btn-->
                  <!--                                  v-close-popup-->
                  <!--                                  :label="getRes('System.Button.Close')"-->
                  <!--                                  color="primary"-->
                  <!--                                  flat-->
                  <!--                                />-->
                  <!--                              </div>-->
                  <!--                            </q-date>-->
                  <!--                          </q-popup-proxy>-->
                  <!--                        </q-icon>-->
                  <!--                      </template>-->
                  <!--                    </q-input>-->
                  <!--                  </div>-->

                  <div class="col-2 cust-label-container">
                    <label>{{ getRes("CustMenu.RegistrationDateFrom") }}</label>
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="registrationDateFrom"
                      :rules="['date']"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="registrationDateFrom"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{
                        getRes("Form.Field.to")
                      }}</label>
                  </div>
                  <div class="col-4">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="registrationDateTo"
                      :rules="['date']"
                      :error="!!issueDateToError"
                      :error-message="issueDateToError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              @input="issueDateChange"
                              v-model="registrationDateTo"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
                <div class="row items-center">
                  <div class="col-1 cust-label-container">
                    <label style="padding: 0 10px">{{ getRes("Form.Field.SupplierName") }}</label>
                  </div>
                  <div class="col-5">
                    <q-input
                      v-model="supplierName"
                      label=""
                      :rules="['string']">
                    </q-input>
                  </div>

                  <div class="col-1 cust-label-container">
                    <label style="padding: 0 10px">{{ getRes("Form.Field.SupplierNumber") }}</label>
                  </div>
                  <div class="col-5">
                    <q-input
                      v-model="supplierNumber"
                      label=""
                      :rules="['number']">
                    </q-input>
                  </div>


                  <!--                  <div class="col-1 cust-label-container">-->
                  <!--                    <label style="padding: 0 10px">{{ getRes("Form.Field.AdmissionRegion") }}</label>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-3">-->
                  <!--                    <q-input-->
                  <!--                      v-model="admissionRegion"-->
                  <!--                      label=""-->
                  <!--                      :rules="['string']">-->
                  <!--                    </q-input>-->
                  <!--                  </div>-->
                </div>
                <!--                <div class="row items-center">-->
                <!--               -->

                <!--                  <div class="col cust-label-container">-->
                <!--                    <label style="padding: 0 10px">{{-->
                <!--                        getRes("Form.Field.ProductCategoryL1")-->
                <!--                      }}</label>-->
                <!--                  </div>-->
                <!--                  <div class="col">-->
                <!--                    <q-input-->
                <!--                      v-model="productCategoryL1"-->
                <!--                      label=""-->
                <!--                      :rules="['string']">-->
                <!--                    </q-input>-->
                <!--                  </div>-->

                <!--                  <div class="col cust-label-container">-->
                <!--                    <label style="padding: 0 10px">{{-->
                <!--                        getRes("Form.Field.ProductCategoryL2")-->
                <!--                      }}</label>-->
                <!--                  </div>-->
                <!--                  <div class="col">-->
                <!--                    <q-input-->
                <!--                      v-model="productCategoryL2"-->
                <!--                      label=""-->
                <!--                      :rules="['string']">-->
                <!--                    </q-input>-->
                <!--                  </div>-->

                <!--                  <div class="col cust-label-container">-->
                <!--                    <label style="padding: 0 10px">{{-->
                <!--                        getRes("Form.Field.ProductCategoryL3")-->
                <!--                      }}</label>-->
                <!--                  </div>-->
                <!--                  <div class="col">-->
                <!--                    <q-input-->
                <!--                      v-model="productCategoryL3"-->
                <!--                      label=""-->
                <!--                      :rules="['string']">-->
                <!--                    </q-input>-->
                <!--                  </div>-->

                <!--                  <div class="col cust-label-container">-->
                <!--                    <label style="padding: 0 10px">{{-->
                <!--                        getRes("Form.Field.ProductCategoryL4")-->
                <!--                      }}</label>-->
                <!--                  </div>-->
                <!--                  <div class="col">-->
                <!--                    <q-input-->
                <!--                      v-model="productCategoryL4"-->
                <!--                      label=""-->
                <!--                      :rules="['string']">-->
                <!--                    </q-input>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="row items-center">
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  outline
                  color="primary"
                  :label="getRes('System.Button.Export')"
                  @click="exportExcel('registeredSupplierMaster')"
                ></q-btn>
              </div>
              <div class="col-1 cust-label-container items-center">
                <q-btn
                  outline
                  color="primary"
                  :label="getRes('System.Button.Clear')"
                  @click="filter"
                ></q-btn>
              </div>
            </div>
          </q-tab-panel>

        </q-tab-panels>
      </q-card>
    </div>
  </div>
</template>

<script>
import {date} from "quasar";

const capexConsultancyList = [
  {label: "Form.Option.Consultancy"},
  {label: "Form.Option.NonConsultancy"},
  {label: "Form.Option.FAndB"},
];
const capexRoutineList = [{label: "CapexRoutine"}, {label: "Routine OPEX"}];

export default {
  name: "GenerateReport",

  data() {
    return {
      tab: "lateSubmission",
      tenderRef: "",
      buyer: "",
      category: "",
      closingDateFrom: "",
      closingDateTo: "",
      closingDateToError: "",
      issueDateFrom: "",
      issueDateTo: "",
      issueDateToError: "",
      capexConsultancy: "",
      capexRoutine: "",
      capexConsultancyList,
      capexRoutineList,
      registrationDateFrom: "",
      registrationDateTo: "",
      supplierName: "",
      supplierNumber: "",
      admissionRegion: "",
      productCategoryL1: "",
      productCategoryL2: "",
      productCategoryL3: "",
      productCategoryL4: ""
    };
  },
  methods: {
    filter() {
      this.$data.tenderRef = "";
      this.$data.buyer = "";
      this.$data.category = "";
      this.$data.closingDateFrom = "";
      this.$data.issueDateFrom = "";
      this.$data.closingDateTo = "";
      this.$data.issueDateTo = "";
      this.$data.capexConsultancy = "";
      this.$data.capexRoutine = "";
      this.$data.registrationDateFrom = "";
      this.$data.registrationDateTo = "";
      this.$data.supplierName = "";
      this.$data.supplierNumber = "";
      this.$data.admissionRegion = "";
      this.$data.productCategoryL1 = "";
      this.$data.productCategoryL2 = "";
      this.$data.productCategoryL3 = "";
      this.$data.productCategoryL4 = "";
    },
    async exportExcel(reportName) {
      console.log("data", this.$data);
      const {hkjc} = this.$proSmart;
      let reportInfo;
      switch (reportName) {
        case "lateSubmission":
          reportInfo = await hkjc.GenerateLateSubmissionReport(this);
          break;
        case "bidderList":
          reportInfo = await hkjc.GenerateBidderListApprovalReport(this);
          break;
        case "extensionAndAbortion":
          reportInfo = await hkjc.GenerateBidderListApprovalReport(this);
          break;
        case "tenderMaster":
          reportInfo = await hkjc.GenerateTenderReport(this);
          break;
        case "rfqMaster":
          reportInfo = await hkjc.GenerateRfqReport(this);
          break;
        case "lightWeightSupplierMaster":
          console.log(typeof this.registrationDateFrom)
          reportInfo = await hkjc.GenerateLightWeightSupplierReport(
            this,
            /*this.issueDateFrom,
            this.issueDateTo,*/
            this.registrationDateFrom,
            this.registrationDateTo,
            this.supplierName,
            this.supplierNumber,
            this.admissionRegion,
            this.productCategoryL1,
            this.productCategoryL2,
            this.productCategoryL3,
            this.productCategoryL4
          );
          console.log(this.registrationDateFrom)
          break;
        case "registeredSupplierMaster":
          reportInfo = await hkjc.GenerateRegisteredSupplierReport(this,
            /*this.issueDateFrom,
            this.issueDateTo,*/
            this.registrationDateFrom,
            this.registrationDateTo,
            this.supplierName,
            this.supplierNumber,
            this.admissionRegion,
            this.productCategoryL1,
            this.productCategoryL2,
            this.productCategoryL3,
            this.productCategoryL4
          );
          break;
      }
      console.log("report info", reportInfo);
      const {fileId, filename, fileType} = reportInfo;
      const diskFile = "";
      console.log("fileId", fileId);
      console.log("fileName", filename);
      console.log("fileType", fileType);
      await hkjc.generateFile(this, fileId, filename, fileType, diskFile);
    },
    closeingDateChange() {
      this.closingDateToError = "";

      let dateFrom = null;
      let dateTo = null;

      if (!!this.closingDateFrom && date.isValid(this.closingDateFrom)) {
        dateFrom = new Date(this.closingDateFrom);
      }

      if (!!this.closingDateTo && date.isValid(this.closingDateTo)) {
        dateTo = new Date(this.closingDateTo).setHours(23, 59, 59, 999);
      }

      if (!!dateFrom && !!dateTo && dateFrom > dateTo) {
        this.closingDateToError =
          "Closing Date to cannot be earlier than Closing Date from";
        return;
      }
    },
    issueDateChange() {
      this.closingDateToError = "";

      let dateFrom = null;
      let dateTo = null;

      if (!!this.closingDateFrom && date.isValid(this.closingDateFrom)) {
        dateFrom = new Date(this.closingDateFrom);
      }

      if (!!this.closingDateTo && date.isValid(this.closingDateTo)) {
        dateTo = new Date(this.closingDateTo).setHours(23, 59, 59, 999);
      }

      if (!!dateFrom && !!dateTo && dateFrom > dateTo) {
        this.closingDateToError =
          "Closing Date to cannot be earlier than Closing Date from";
        return;
      }
    },
  },
};
</script>
